import CustomButton from '@/apps/Shared/CustomButton/CustomButton'
import { IconDowloadBlue, IconEmail, IconWhatSapp } from '@/assets/IconV2/IconsSvg'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import { postDataDownloadPdf } from '../../Transactions/VankPay/atoms/postDataDownloadPdf';
import { ServicesPrints } from '../../Transactions/VankPay/service/ServicesPrints';
import { ExternalServices } from '../../Transactions/VankPay/service/ExternalServices';
import { default as toastHook } from "@/hooks/useToast"; // import
import { useTranslation } from 'react-i18next';
import { ServicesPrintsFiat } from '../../Transactions/Fiat/Services/ServicesPrintsFiat';
import { DownloadIcon, SendEmailIcon } from '@/apps/Shared/ToastContainer/Icons';
import SpinnerShared from './SpinnerShared';


const SharedButtons = ({ typeModule, email, CODERECEIPT, GENERATEDBY, URL, TypeAction }:
    { CODERECEIPT?: string, GENERATEDBY?: any, URL?: string, TypeAction?: string, email?: string, typeModule?: number }) => {
    const toast = toastHook(); // use hook   
    const dataDownloadPdf = useRecoilValue(postDataDownloadPdf);
    const printServices = new ServicesPrints()
    const printFiat = new ServicesPrintsFiat();
    const externalService = new ExternalServices()
    const [visible, setVisible] = useState(false)
    const [visibleDownload, setVisibleDownload] = useState(false)
    const [visibleEmail, setVisibleEmail] = useState(false)
    const [visibleWhatSapp, setVisibleWhatSapp] = useState(false)
    const [t] = useTranslation("global");

    //const {addToast} = useToastDispatch();
    const [dataUser, setDataUser] = useState<any>({
        // Usar el contexto de notificaciones
        EMAIL: '',
        CRYPTO: '',
        AMOUNT: '',
        CODECRYPTO: '',

    });

    const getBase64 = async (action: string) => {
        if (typeModule == 3) {
            const dataPdf = {
                CODERECEIPT: CODERECEIPT,
                URL: URL,
                ACTION: action,
                TO_EMAIL: email,
            };

            try {
                const response = await printFiat.getPrintsFiat(dataPdf);

                return response.body.url === undefined ? response.body : response.body.url;
            } catch (error) {
                toast.error(t(
                    "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text3"
                ), {
                    position: "top-right", // Obligatorio
                    autoClose: 5000, // Opcional
                    //iconSuccess: <IconSuccess />, // Opcional
                    hideProgressBar: true, // Opcional
                    isHovered: true,
                    height: "100px",
                });
            }
        } else {
            const dataPdf = {
                CODERECEIPT: CODERECEIPT,
                GENERATEDBY: GENERATEDBY,
                URL: URL,
                ACTION: action,
                TYPESEND: TypeAction,
                TO_EMAIL: email,
            };

            try {
                const response = await printServices.getPrints(dataPdf);
                return response.body.url === undefined ? response.body : response.body.url;
            } catch (error) {
                toast.error(t(
                    "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text3"
                ), {
                    position: "top-right", // Obligatorio
                    autoClose: 5000, // Opcional
                    //iconSuccess: <IconSuccess />, // Opcional
                    hideProgressBar: true, // Opcional
                    isHovered: true,
                    height: "100px",
                });
            }
        }
    };

    const redirigirAWhatsApp = async () => {

        setVisibleWhatSapp(true);

        const dataBase64 = await getBase64("WHATSSAPP");
        // Reemplaza con tu mensaje
        const mesagge = `${t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text1")} ${dataBase64}`
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(mesagge)}`;
        window.open(url, '_blank');
        setVisibleDownload(true)

        setTimeout(() => {
            setVisibleWhatSapp(false);
            setVisibleDownload(false)
        }, 2000)

    };

    // Para redirigir a Email
    const fetchData = async () => {
        try {
            setVisible(true);

            const response = await getBase64("DOWNLOAD");

            fetch(response)
                .then((response) => response.blob())
                .then((blob) => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${CODERECEIPT}.pdf`; // Nombre sugerido para el archivo descargado
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                })
                .catch((error) => {
                    console.error(
                        t(
                            "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text6"),
                        error
                    );
                    // Toast personalizado para error
                    toast.error(t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text6"),
                        {
                            position: "top-right", // Obligatorio
                            autoClose: 5000, // Opcional
                            iconSuccess: <DownloadIcon />, // Opcional
                            hideProgressBar: true, // Opcional
                            isHovered: true,
                            height: "100px",
                        });
                });

            setVisibleDownload(true);

            setTimeout(() => {
                setVisible(false)
                setVisibleDownload(false)
            }, 2000)

            toast.success(
                <>
                    <b>{t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text4")}</b>
                    <br />
                    {t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text4.1")}
                </>,

                {
                    position: "top-right", // Obligatorio
                    autoClose: 5000, // Opcional
                    iconSuccess: <DownloadIcon />, // Opcional
                    hideProgressBar: true, // Opcional
                    isHovered: true,
                    height: "100px",
                }
            );
        } catch (error) {
            setVisible(false);

            // Toast personalizado de error
            toast.error(t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text3"), {
                position: "top-right", // Obligatorio
                autoClose: 5000, // Opcional
                iconSuccess: <DownloadIcon />, // Opcional
                hideProgressBar: true, // Opcional
                isHovered: true,
                height: "100px",
            });
        }
    };

    const enviarEmail = async () => {
        setVisibleEmail(true);

        const dataBase64 = await getBase64("EMAIL");

        if (!dataBase64) {
            setVisibleEmail(false);
            return;
        }

        const cuerpo = `${t(
            "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text1"
        )} ${dataBase64}`;
        console.log("Email body:", cuerpo);

        externalService.shareEmail({
            destinatario: dataUser?.EMAIL,
            asunto: "Resivo",
            cuerpo: cuerpo,
        });

        setVisibleDownload(true);

        setTimeout(() => {
            setVisibleEmail(false);
            setVisibleDownload(false)
        }, 2000)

        toast.success(
            <>
                <b>{t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text5")}</b>
                <br />
                {t("Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text5.1")}
            </>,

            {
                position: "top-right", // Obligatorio
                autoClose: 5000, // Opcional
                iconSuccess: <SendEmailIcon />, // Opcional
                hideProgressBar: true, // Opcional
                isHovered: true,
                height: "100px",
            }
        );

    };

    return (
        <div className='flex items-center gap-2 w-full  bg-[var(--background-layaout-light)] dark:bg-[var(--Background-Inputs)] dark:text-[var(--content-main-gray-dark)] h-[42px]  rounded-full relative '>

            {(!visible) && (!visibleEmail) && (!visibleWhatSapp) ?

                <>
                    <CustomButton onclick={() => redirigirAWhatsApp()} label={<IconWhatSapp />} className='hover:bg-[#81828225] rounded-r-full rounded-l-full h-full w-[33%] flex flex-col  items-center justify-center' />
                    <span className='border-l-[1px] border-[var(--strokes-network-light)] dark:border-[#2D2E2F] w-1 h-6'></span>
                    <CustomButton disabled={visibleEmail} onclick={() => enviarEmail()} label={<IconEmail />} className='hover:bg-[#81828225] w-[33%] h-full  flex flex-col items-center justify-center rounded-r-full rounded-l-full ' />
                    <span className='border-l-[1px] border-[var(--strokes-network-light)] dark:border-[#2D2E2F] w-1 h-6'></span>
                    <CustomButton disabled={visible} onclick={() => fetchData()} label={<IconDowloadBlue />} className='hover:bg-[#81828225] rounded-r-full rounded-l-full w-[33%] h-full  flex flex-col items-center justify-center' />
                </>
                :
                <>
                    {visibleEmail && <SpinnerShared text={t("buttonsShared.textSharedEmail")} fill='#F98888' statusAction={visibleDownload} textPostAction={t('buttonsShared.textSend')}/>}
                    {visible && <SpinnerShared text={t("buttonsShared.textDownloadPdf")} fill='#6FC8FF' statusAction={visibleDownload} textPostAction={t('buttonsShared.textDownload')} />}
                    {visibleWhatSapp && <SpinnerShared text={t("buttonsShared.textSharedWhastApp")} fill='#6AD67B' statusAction={visibleDownload} textPostAction={t('buttonsShared.textconect')}/>}
                </>

            }

        </div>
    )
}

export default SharedButtons