import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { jwtDecode } from "jwt-decode";
import { StorageService } from "../services/StorageServiceVanKPay";
import { ServiceUsers } from "../Transactions/VankPay/service/ServicesUsers";
import { ServiceOrchestrator } from '../Transactions/VankPay/service/ServicesOrchestrator';
import { useState } from "react";


const formtDate = (date: string): string => {
  const screenWidth: number = window.innerWidth;

  // Verificar si la pantalla está entre 360px y 1308px
  if (
    screenWidth >= 320 &&
    screenWidth <= 1308 &&
    screenWidth !== 768 &&
    !(screenWidth >= 768 && screenWidth <= 1280)
  ) {
    // Mostrar la fecha y la hora en formato de 24 horas con "Hrs"
    return new Date(date)
      .toLocaleString("es-ES", {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        //minute: "2-digit",
        //hour12: false, // Formato de 24 horas
      })
      .replace(",", " ")
      .replace(
        /^(\d{1,2})\s(\w{3})/,
        (_, day, month) =>
          `${month?.charAt(0).toUpperCase() + month?.slice(1)} ${day},`
      )
      .replace(/\d{2}:\d{2}/, (match) => `${match} Hrs`); // Añadir "Hrs" después de la hora
  } else if (screenWidth >= 768 && screenWidth <= 1280) {
    // Solo mostrar la fecha
    return new Date(date)
      .toLocaleString("es-ES", {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(",", ", ")
      .replace(
        /^(\d{1,2})\s(\w{3})/,
        (_, day, month) =>
          `${day} ${month?.charAt(0).toUpperCase() + month?.slice(1)}`
      );
  } else {
    // Solo mostrar la fecha
    return new Date(date)
      .toLocaleString("es-ES", {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
      })
      .replace(",", ", ")
      .replace(
        /^(\d{1,2})\s(\w{3})/,
        (_, day, month) =>
          `${day} ${month?.charAt(0).toUpperCase() + month?.slice(1)}`
      );
  }
};

// Función para formatear la fecha en la zona horaria detectada automáticamente
const formatDateP = (
  date: Date | string,
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  return formatInTimeZone(new Date(date), timeZone, "yyyy-MM-dd hh:mm aaaa");
};

//para validara datos que vengan incriptados
const decryptInfo = (data) => {
  try {
    return !Number.isNaN(atob(data)) ? data : atob(data);
  } catch {
    return data;
  }
};

// Función para verificar si es un correo electrónico
const isEmail = (text) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular básica para correos electrónicos
  return emailRegex.test(text);
};

// Función para capitalizar nombres

const capitalizeName = (name) => {
  if (!name || isEmail(name)) return name; // Si es un correo, lo devolvemos tal cual
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formtDateModal = (date: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/Bogota", // Cambia esto según tu zona horaria
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Formato 24 horas
  };

  const formattedDate = new Date(date)
    .toLocaleString("es-ES", options)
    .replace(",", ", ");

  return `${formattedDate} (UTC-5)`; // Añadir el indicador de la zona horaria
};

export const formatBalance = (amount: number) => {
  let formattedAmount = amount?.toFixed(2);
  formattedAmount = formattedAmount?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return ` $${formattedAmount}`;
};

//funcion para traducir las fechas
const TranslatedDate = (dateStr: any, lang: string = "es") => {
  const locales: any = {
    es: "es-ES",
    en: "en-US",
    pt: "pt-PT",
  };

  const language = locales[lang] || "es-ES";

  return (
    new Date(dateStr)
      .toLocaleString(language, {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", ", ") + " (UTC-5)"
  );
};

// 28 de octubre de 2024, 12:26 (UTC-5)
// 28 oct 2024, 12:26 (UTC-5)

//funcion para traducir texto de la db
const translateAcountType = (
  term: string,
  dictionary: any,
  translations: any
): string => {
  return translations[dictionary]?.[term] || term;
};

const formatAmountVankpay = (amount: string, action: string, asset: string) => {
  // const formattedAmount = parseFloat(amount)
  //   .toFixed(2) // Asegura que tenga dos decimales
  //   .replace(/\d(?=(\d{3})+\.)/g, '$&,') // Añade la coma para miles y punto para decimales

  const formattedAmount = formatBalance(parseFloat(amount));

  const formattedWithComma = formattedAmount.replace(".", "."); // Reemplaza el punto por coma para decimales

  const sign = action === "OUTPUT" ? "-" : "+";

  return `${sign} ${formattedWithComma} ${asset}`;
};

const formatBankName = (name) => {
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => {
      if (word === "s.a") return word.toUpperCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const getCurrency = async () => {
  

  try {

      const token = await StorageService.get("token")
      const tokenDecodi = jwtDecode(token)
      const value = tokenDecodi["user"].USERDATA.VIRTUALEMAIL;
      const userServices = new ServiceUsers();
      const [arrayCurrency, setArrayCurrency] = useState<any>([]);
      

      const dataT = {
          VIRTUALEMAIL: value
      }

      

      const data = await userServices.getCurrencies(dataT);
     
      
      setArrayCurrency(data.body)
      getCrypto()
      return data.body;

  } catch (error) {
      
  }
}

const getCrypto = async () => {
  const serviceOrchestractor = new ServiceOrchestrator();
  const [arrayCrypto, setArrayCrypto] = useState<any>([]);


  try {

      const token = await StorageService.get("token")
      const tokenDecodi = jwtDecode(token)
      const value = tokenDecodi["user"].USERDATA.VIRTUALEMAIL;

     

      const dataT = {
          GENERATEDBY: value
      }

      const data = await serviceOrchestractor.getCryptos(dataT);

      setArrayCrypto(data.body.response.body)

      const array = data.body.response.body;


      return array;

  } catch (error) {

  }
}

const formatEmail = (email) => {
  if (!email || typeof email !== "string") {
    return "";
  }

  const [localPart, domain] = email?.split("@");
  if (!localPart || !domain) {
    return email; // Retorna el email original si el formato no es válido
  }

  // Mostrar la primera letra y los últimos dos caracteres del nombre de usuario
  const maskedLocalPart = `${localPart[0]}*****${localPart?.slice(-2)}`;
  return `${maskedLocalPart}@${domain}`;
};

const formatPhone = (number, maskChar = '*') => {
  if (!number || number === "") return;
  const numStr = number?.toString(); // Convertir a string
  if (numStr?.length <= 3) return numStr; // Si tiene menos de 4 caracteres, no enmascarar
  const first = numStr[0]; // Primer carácter
  const lastTwo = numStr?.slice(-2); // Últimos dos caracteres
  const masked = maskChar?.repeat(numStr.length - 3); // Generar los asteriscos
  return `${first}${masked}${lastTwo}`; // Combinar todo
}

export {
  formtDate,
  formatDateP,
  decryptInfo,
  capitalizeName,
  formtDateModal,
  formatAmountVankpay,
  TranslatedDate,
  translateAcountType,
  formatBankName,
  formatEmail,
  formatPhone,
  getCurrency
};
