import CustomButton from '@/apps/Shared/CustomButton/CustomButton'
import React from 'react'
import { useTranslation } from 'react-i18next';

export const CustomButtonsForms = ({ onClickClear, onClickContinue, disabled, type, label, labelClear }: { labelClear?: string, onClickClear?: () => void, onClickContinue?: () => void, disabled?: boolean, type?: string, label?: string }) => {

    return (
        <div className='w-full flex gap-6'>
            <div className='w-1/2'>
                <CustomButton onclick={onClickClear} type={type} label= {labelClear} className='font-medium border-[1px] border-[var(--content-main-black-light)] dark:border-[#FFFFFF] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] mobile:text-[12px] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] tablet:text-[16px] rounded-full 2xl:h-[42px] xl:h-[42px] lg:h-[42px] tablet:h-[42px] mobile:h-[32px] w-full' />
            </div>
            <div className='w-1/2'>
                <CustomButton onclick={onClickContinue} label={label} type={type} 
                className={`${disabled?'bg-[var(--background-disabled-button)] font-medium  dark:bg-[#818282] text-[var(--content-main-gray-light)] dark:text-[#EAEAEA]':'bg-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:bg-[#FFFFFF] dark:hover:text-[#2D2E2F] mobile:text-[12px] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] tablet:text-[16px]  hover:text-[var(--branding-brand-yellow-light)] 2xl:h-[42px] xl:h-[42px] lg:h-[42px] tablet:h-[42px] mobile:h-[32px]'} mobile:text-[12px] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] tablet:text-[16px]  2xl:h-[42px] xl:h-[42px] lg:h-[42px] tablet:h-[42px] mobile:h-[32px] rounded-full h-[42px] w-full`} disabled={disabled} />
            </div>
        </div>
    )
}
