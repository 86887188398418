import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { environment } from "@/environments/environment.dev";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
environment;

export interface HttpParameters {
  url: string;
  method?: string;
  headers?: any;
  data?: any;
  query?: string;
}

export class AxiosClientRepository {
  private axiosInstance: AxiosInstance;

  constructor() {
    // No establecemos el baseUrl aquí, lo configuraremos dinámicamente en cada método de solicitud
    // this.baseUrl = environment.url_servers.url_users; // URL base del servidor
    // this.config();
  }

  config() {
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Interceptor para asignar los encabezados
    this.axiosInstance.interceptors.request.use(async (config) => {
      const token = await StorageService.get("verify");

      config.headers["Authorization"] = (await StorageService.get("token"))
        ? `Bearer ${await StorageService.get("token")}`
        : "";

      // Verificar si la IP ya está en localStorage
      let ip = localStorage.getItem("ip");
      const ipTimestamp = localStorage.getItem("ipTimestamp");
      const currentTime = new Date().getTime();
      const halfHour = 30 * 60 * 1000; // 30 minutos en milisegundos

      // Si la IP está almacenada pero ha pasado más de media hora, eliminarla
      if (ip && ipTimestamp && currentTime - parseInt(ipTimestamp) > halfHour) {
        localStorage.removeItem("ip");
        localStorage.removeItem("ipTimestamp");
        ip = null;
      }

      if (!ip) {
        // Si la IP no está almacenada, realizar la solicitud para obtenerla
        try {
          const response = await axios.get(
            `${environment.url_servers.url_users}/secure/v1/ip`
          );
          
          ip = response.data.body; // Obtener la IP del cuerpo de la respuesta
          // Almacenar la IP y el timestamp en localStorage
          localStorage.setItem("ip", ip);
          localStorage.setItem("ipTimestamp", currentTime.toString());
        } catch (err) {
          console.error("Error al obtener la IP:", err);
          // Lanzar un error para bloquear todas las solicitudes
          return Promise.reject(
            new Error(
              "No se pudo obtener la IP. Todas las solicitudes están bloqueadas."
            )
          );
        }
      }

      // Asignar la IP a los encabezados
      config.headers["Ip"] = ip || "";

      config.headers["verify"] = (await StorageService.get("verify"))
        ? await StorageService.get("verify")
        : "";

      config.headers["geolocation"] = null;
      config.headers["Accept-Language"]=localStorage.getItem("i18nextLng")

      // try {
      //   const geolocation = await axios.get(
      //     "https://ipinfo.io/json?token=d9ba9512522d1b"
      //   );
      //   config.headers["geolocation"] = geolocation.data;
      // } catch {
      //   config.headers["geolocation"] = "";
      // }
      // config.headers["geolocation"] = await axios
      //   .get("https://ipinfo.io/json?token=d9ba9512522d1b")
      //   .then((res) => res.data);

     

      return config;
    });

    // Manejador de errores global para Axios
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error?.response?.status;
        const data = error?.response?.data;
        let message: string;

        

        if (error.response) {
          if (status === 400) {
            message = "Error 400: Bad Request - Error al procesar los datos";
            // document.dispatchEvent(new Event("Error400"));
          } else if (status === 401) {
            message =
              "Error de autorización: No tienes permiso para acceder a estos datos.";
            // document.dispatchEvent(new Event("Error401"));
          } else if (status === 404) {
            message = "Error: No se encontraron los datos solicitados.";
            // document.dispatchEvent(new Event("Error404"));
          } else if (status === 422) {
            message = "Error 422: Unprocessable Entity - Error en los datos";
            // return
            // document.dispatchEvent(
            //   new CustomEvent("myCustomEvent", {
            //     detail: {
            //       status: "ERROR",
            //       message:
            //         "La estructura de los datos es incorrecta. Por favor, contacte con soporte técnico para obtener ayuda.",
            //       show: true,
            //     },
            //   })
            // );
          } else if (status >= 500) {
            message = "Error del servidor: Hubo un problema con el servidor.";
            // document.dispatchEvent(new CustomEvent('isProgress', {
            //   detail: {
            //     title: 'Error del servidor temporal',
            //     message: "Estamos experimentando problemas técnicos en este momento. Por favor, intenta nuevamente más tarde.",
            //     show: true,
            //   }
            // }));
          } else {
            message = "Error desconocido";
          }
        } else if (error.request) {
          message = "Error de red: No se pudo conectar al servidor.";
          // document.dispatchEvent(new Event("NetworkError"));
        } else {
          message = "Error: Hubo un problema al procesar tu solicitud.";
          // document.dispatchEvent(new Event("ProcessingError"));
        }
        return Promise.reject({ status, data, message });
      }
    );
  }

  configFile() {
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Interceptor para asignar los encabezados
    this.axiosInstance.interceptors.request.use(async (config) => {
      const token = await StorageService.get("verify");

      config.headers["Authorization"] = (await StorageService.get("token"))
        ? `Bearer ${await StorageService.get("token")}`
        : "";

      // Verificar si la IP ya está en localStorage
      let ip = localStorage.getItem("ip");
      const ipTimestamp = localStorage.getItem("ipTimestamp");
      const currentTime = new Date().getTime();
      const halfHour = 30 * 60 * 1000; // 30 minutos en milisegundos

      // Si la IP está almacenada pero ha pasado más de media hora, eliminarla
      if (ip && ipTimestamp && currentTime - parseInt(ipTimestamp) > halfHour) {
        localStorage.removeItem("ip");
        localStorage.removeItem("ipTimestamp");
        ip = null;
      }

      if (!ip) {
        // Si la IP no está almacenada, realizar la solicitud para obtenerla
        try {
          const response = await axios.get(
            `${environment.url_servers.url_users}/secure/v1/ip`
          );
          
          ip = response.data.body; // Obtener la IP del cuerpo de la respuesta
          // Almacenar la IP y el timestamp en localStorage
          localStorage.setItem("ip", ip);
          localStorage.setItem("ipTimestamp", currentTime.toString());
        } catch (err) {
          console.error("Error al obtener la IP:", err);
          // Lanzar un error para bloquear todas las solicitudes
          return Promise.reject(
            new Error(
              "No se pudo obtener la IP. Todas las solicitudes están bloqueadas."
            )
          );
        }
      }

      // Asignar la IP a los encabezados
      config.headers["Ip"] = ip || "";

      config.headers["verify"] = (await StorageService.get("verify"))
        ? await StorageService.get("verify")
        : "";

      config.headers["geolocation"] = {};

      // try {
      //   const geolocation = await axios.get(
      //     "https://ipinfo.io/json?token=d9ba9512522d1b"
      //   );
      //   config.headers["geolocation"] = geolocation.data;
      // } catch {
      //   config.headers["geolocation"] = "";
      // }
      // config.headers["geolocation"] = await axios
      //   .get("https://ipinfo.io/json?token=d9ba9512522d1b")
      //   .then((res) => res.data);

      

      return config;
    });

    // Manejador de errores global para Axios
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const status = error?.response?.status;
        const data = error?.response?.data;
        let message: string;

      

        if (error.response) {
          if (status === 400) {
            message = "Error 400: Bad Request - Error al procesar los datos";
            // document.dispatchEvent(new Event("Error400"));
          } else if (status === 401) {
            message =
              "Error de autorización: No tienes permiso para acceder a estos datos.";
            // document.dispatchEvent(new Event("Error401"));
          } else if (status === 404) {
            message = "Error: No se encontraron los datos solicitados.";
            // document.dispatchEvent(new Event("Error404"));
          } else if (status === 422) {
            message = "Error 422: Unprocessable Entity - Error en los datos";
            // return
            // document.dispatchEvent(
            //   new CustomEvent("myCustomEvent", {
            //     detail: {
            //       status: "ERROR",
            //       message:
            //         "La estructura de los datos es incorrecta. Por favor, contacte con soporte técnico para obtener ayuda.",
            //       show: true,
            //     },
            //   })
            // );
          } else if (status >= 500) {
            message = "Error del servidor: Hubo un problema con el servidor.";
            // document.dispatchEvent(new CustomEvent('isProgress', {
            //   detail: {
            //     title: 'Error del servidor temporal',
            //     message: "Estamos experimentando problemas técnicos en este momento. Por favor, intenta nuevamente más tarde.",
            //     show: true,
            //   }
            // }));
          } else {
            message = "Error desconocido";
          }
        } else if (error.request) {
          message = "Error de red: No se pudo conectar al servidor.";
          // document.dispatchEvent(new Event("NetworkError"));
        } else {
          message = "Error: Hubo un problema al procesar tu solicitud.";
          // document.dispatchEvent(new Event("ProcessingError"));
        }
        return Promise.reject({ status, data, message });
      }
    );
  }

  async post(httpParameters: HttpParameters) {
    try {
      this.config();
      const { url, data } = httpParameters;
      const response = await this.axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async put(httpParameters: HttpParameters) {
    try {
      this.config();
      const { url, data } = httpParameters;
      const response = await this.axiosInstance.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async get(httpParameters: HttpParameters) {
    try {
      this.config();
      const { url } = httpParameters;
      const response = await this.axiosInstance.get(url);
      return response.data;
    } catch (error) {
      // console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }

  async postFile(httpParameters: HttpParameters) {
    try {
      this.configFile();
      const { url, data } = httpParameters;
      const response = await this.axiosInstance.post(url, data);
      return response;
    } catch (error) {
      // console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }

  async putFile(httpParameters: HttpParameters) {
    try {
      this.configFile();
      const { url, data } = httpParameters;
      const response = await this.axiosInstance.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(httpParameters: HttpParameters) {
    try {
      this.config();
      const { url } = httpParameters;
      const response = await this.axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      // console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }
}
