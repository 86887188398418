import { useEffect, useState } from "react";
import HeaderPage from "../shared/HeaderPage/HeaderPage";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const Recipient = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();

  const nav = [
    {
      id: 1,
      name: t(
        "Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.VankPay"
      ),
      link: "vankpay",
      active: true,
    },
    {
      id: 3,
      name: t(
        "Vank.Transaction.VankPay.SendVankPay.HeaderOfShares.Actions.Fiat"
      ),
      link: "dinero",
      active: false,
    },
  ];

  const [isActive, setIsActive] = useState(() => {
    // Recuperar el estado del botón activo desde sessionStorage
    const savedActiveButton = sessionStorage.getItem("activeButton");
    return savedActiveButton ? JSON.parse(savedActiveButton) : true;
  });

  useEffect(() => {
    // Guardar la última ruta visitada y el botón activo en sessionStorage cada vez que cambia la ubicación
    if (location.pathname !== "/recipient") {
      sessionStorage.setItem("lastVisitedRecipientPath", location.pathname);
      sessionStorage.setItem("activeButton", JSON.stringify(isActive));
    }
  }, [location.pathname, isActive]);

  useEffect(() => {
    // Leer la última ruta visitada desde sessionStorage cuando se accede a /recipient
    const savedPath = sessionStorage.getItem("lastVisitedRecipientPath");
    if (location.pathname === "/recipient" && savedPath) {
      navigate(savedPath, { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <div className="w-full flex flex-col  gap-4 2xl:py-[32px] xl:py-[32px] lg:py-[32px] tablet:py-[32px] mobile:py-[0px] 2xl:px-[64px] xl:px-[64px] lg:px-[64px] tablet:px-[32px] mobile:px-[16px]">
      <div className="flex flex-col gap-4">
        <HeaderPage
          visibleItem={false}
          title={t("Vank.Transaction.VankPay.SendVankPay.text1")}
          subtext={t("Vank.Transaction.VankPay.SendVankPay.text2")}
          buttonRechage={false}
        />

        <div
          className=" bg-[#FFF] dark:bg-[var(--background-component-module-dark)] rounded-[6px] justify-start items-center inline-flex p-[2px]
                          2xl:h-9 xl:h-9 lg:h-9 tablet:h-9 mobile:min-h-[26px]
                          2xl:max-w-min xl:max-w-min lg:max-w-min tablet:max-w-min mobile:w-full
          "
        >
          {nav.map(({ active, name, link, id }) => (
            <button
              key={id}
              className={`2xl:w-[100px] xl:w-[100px] lg:w-[100px] tablet:w-[100px] mobile:w-full 2xl:h-[28px] xl:h-[28px] lg:h-[28px] tablet:h-[28px] mobile:h-[22px] ${
                active === isActive
                  ? "flex items-center justify-center px-6 rounded-[4px] bg-[--color-darkest-grey] dark:bg-[#FFFFFF]"
                  : "flex items-center justify-center px-6 rounded-[4px] "
              } cursor-pointer`}
              onClick={() => {
                setIsActive(active);
                navigate(link);
              }}
            >
              {active === isActive ? (
                <span className="text-[--color-lightest-white] dark:text-[#2D2E2F] text-[14px] leading-[18px]">
                  {name}
                </span>
              ) : (
                <span className="text-[#2D2E2F] dark:text-[#FFFFFF] text-[14px] leading-[18px]">
                  {name}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Recipient;
