import { ArrowHide } from "@/assets/Icon/ArrowHide";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import Select, { components } from "react-select";

const CustomSelect = ({
  addreese,
  onChange,
  options,
  placeholder,
  value,
  onInputChange
}: {
  addreese?: any;
  onChange?: (e: any) => void;
  options?: [];
  placeholder?: string;
  value?:any,
  onInputChange?:any
}) => {
  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  // Componente personalizado para el icono del dropdown
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <motion.span
          initial={{ rotate: 0 }}
          animate={{ rotate: props.selectProps.menuIsOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          style={{ display: "inline-block" }}
        >
          <ArrowHide
            className=" w-full h-full"
            stroke="var(--content-main-gray-light)"
          />
        </motion.span>
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      className="h-full 2xl:text-[16px] xl:text-[16px] lg:text-[16px] tablet:text-[16px] mobile:text-[13px] 2xl:h-[48px] xl:h-[48px] lg:h-[48px] tablet:h-[48px] mobile:h-[40px]"
      components={{ DropdownIndicator }}
      onMenuOpen={onInputChange}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "transparent" : "transparent",
          outline: state.isFocused ? "transparent" : "transparent",
          boxShadow: state.isFocused ? "none" : "none",
          "&:hover": {
            // Estilo de hover
            borderColor: state.isFocused
              ? ""
              : "var(--branding-brand-yellow-light)", // Cambia el color del
          },
          ":focus": {
            background: "var(--background-layaout-light)",
          },
          background:
            theme === "dark" ? "var(--background-hover-dark)" : "var(--background-layaout-light)",
          color: theme === "dark" ? "#C0C0C1" : "var(--background-hover-dark)",
          borderRadius: "12px",
          height: "100%",
        }),

        menu: (provided) => ({
          ...provided,
          backgroundColor:
            theme === "dark" ? "#2D2E2F" : "var(--background-layaout-light)",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          borderTopRightRadius: "0px",
          borderTopLeftRadius: "0px",
          overflow: "hidden",
          boxShadow: "1px 4px 3px 0px gray",
          marginTop: "-8px",
          width: "100%",
        }),

        option: (provided, state) => ({
          ...provided,
          backgroundColor:
            theme === "dark"
              ? "#2D2E2F"
              : state.isSelected
              ? "var(--background-layaout-light)"
              : state.isFocused
              ? "var(--Background-tab-background)"
              : "var(--background-layaout-light)",
          color:
            theme === "dark"
              ? "#C0C0C1"
              : state.isSelected
              ? "var(--content-main-black-light)"
              : "var(--content-main-black-light)",
          "&:hover": {
            backgroundColor:
              theme === "dark"
                ? "#2D2E2F"
                : state.isSelected
                ? "var(--Background-tab-background)"
                : "var(--Background-tab-background)",
            color:
              theme === "dark" ? "#FFFFFF" : "var(--content-main-black-light)",
          },
          borderTop: "1px solid var(--strokes-separator-separator-light) ",
        }),

        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: state.isFocused ? "green" : "red",
          transition: "rotate-80", // Transición para la rotación
        }),

        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),

        placeholder: (base) => ({
          ...base,
          color:
            theme === "dark" ? "#C0C0C1" : "var(--content-main-black-light)",
        }),

        input: (base) => ({
          ...base,
          color: theme === "dark" ? "#C0C0C1" : "var(--content-main-black-light)", // Color del texto que escribes
        }),

        // Estilo para el valor seleccionado
        singleValue: (base) => ({
          ...base,
          color: theme === "dark" ? "#C0C0C1" : "var(--content-main-black-light)", // Cambia a verde el valor seleccionado
        }),
      }}
    />
  );
};

export default CustomSelect;
