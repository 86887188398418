import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";
import { HttpResponseRecipeintVankPay } from "../../../Recipient/models/ModelRecipientVankPay";

export class ServiceUsers extends AxiosClientRepository {
  async postEmailVerify(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/otpVerifyTypeMessage`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async otpValidRequestUsers(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/validOtpVankCode`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async otpVerifyTypeMessage(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/postOtpVankCode`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getHistoryConver(query?: string) {
    try {
      const url = `${environment.url_servers.url_users}/getHistoryConvert`;
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCurrencies(data: any) {
    const url =
      `${environment.url_servers.url_users}/getCurrencyUsers?query=` +
      JSON.stringify({ VIRTUALEMAIL: data?.VIRTUALEMAIL });

    try {
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getRealTimeValueCoinmarket(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/convert?amount=${data.amount}&symbol=${data.crypto}&convert=${data.currency}`;
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getDataUsers(): Promise<HttpResponseRecipeintVankPay> {
    try {
      const url = `${environment.url_servers.url_users}/GetAllUsersRecipients`;
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getDataUsersSimple() {
    try {
      const url = `${environment.url_servers.url_users}/GetAllUsersRecipients`;
      let response = await this.get({ url });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async putConvertMarket(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/postConvertLimit`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async cancelconvertTransaction(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/cancelconvertTransaction`;
      let response = await this.put({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async putCurrencyInfoData(data?: unknown) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/PutConvertMarket`;
      let response = await this.put({ url, data });
      //   environment.url_servers.url_users,
      //   "PutConvertMarket",
      //   data
      // );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async searchRecipient(data:string) {
    try {
      const url = `${environment.url_servers.url_users}/searchUsersRecipients?query=`+JSON.stringify({context:data});
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
