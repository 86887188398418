const SpinnerShared = ({ text, fill, textPostAction, statusAction }: { text: string, fill: string, textPostAction: string, statusAction: boolean }) => {
    return (
        <div className='text-center w-full text-sm text-[#818282] flex items-center gap-1 justify-center'>

            {!statusAction ?
                <>
                    {text}
                    < svg className='animate-spin' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.4 12C2.4 17.3019 6.69807 21.6 12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69807 17.3019 2.4 12 2.4C6.69807 2.4 2.4 6.69807 2.4 12Z" fill="#EAEAEA" />
                        <path d="M22.8 12C23.4627 12 24.0062 12.5386 23.9401 13.198C23.8232 14.3629 23.5362 15.5066 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.5066 23.5362 14.3629 23.8232 13.198 23.9401C12.5386 24.0062 12 23.4627 12 22.8C12 22.1373 12.5393 21.6077 13.1969 21.5251C14.0463 21.4184 14.8795 21.1982 15.6738 20.8692C16.8385 20.3868 17.8968 19.6797 18.7882 18.7882C19.6797 17.8968 20.3868 16.8385 20.8692 15.6738C21.1982 14.8795 21.4184 14.0463 21.5251 13.1969C21.6077 12.5393 22.1373 12 22.8 12Z" fill={fill} />
                    </svg>
                </>
                :
                <>
                    {textPostAction}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" rx="12" fill="#B7EB8F" />
                        <path d="M6 12.3689L9.76 16.1189L18 7.87891" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </>
            }


        </div >
    )
}

export default SpinnerShared
