import { jwtDecode } from "jwt-decode";

const decodeToken = (token) => {
  try {
    // Decodifica el token
    const decoded = jwtDecode(token);
    // Retorna el valor de `sub`
    return decoded;
  } catch (error) {
    return null;
  }
};



export {
  decodeToken
}