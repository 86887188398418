import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { ArrowHide } from "@/assets/Icon/ArrowHide";
import {
  IconClose,
  IconCrypto,
  IconDolar,
} from "@/assets/IconV2/IconsSvg";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ReceiveModal = ({
  isOpenReload,
  setIsOpenReload,
}: {
  isOpenReload: any;
  setIsOpenReload: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverCrypto, setIsHoverCrypto] = useState(false);
  const [isHoverFiat, setIsHoverFiat] = useState(false);
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const handleMouseHoverClose = (hovered: boolean) => {
    setIsHovered(hovered);
  };

  const handleMouseHoverCrypto = (hovered: boolean) => {
    setIsHoverCrypto(hovered);
  };

  const handleMouseHoverFiat = (hovered: boolean) => {
    setIsHoverFiat(hovered);
  };

  const handleNavigate = (path: string) => {
    setIsOpenReload(false);
    setIsHovered(false);
    setIsHoverCrypto(false);
    setIsHoverFiat(false);
    setTimeout(() => {
      navigate(path);
    }, 40);
  };

  return (
    <CustomModal isOpen={isOpenReload}>


      {/* <div className=" 2xl:w-[396px] xl:w-[396px] lg:w-[396px] md:w-[396px] sm:w-[396px] 2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute mobile:bottom-0    mobile:w-full h-[314px] px-4 py-6 bg-white dark:bg-[#1A1A1B] rounded-2xl flex-col justify-center items-center gap-6 inline-flex"> */}

      <div className="bg-[#FFF] dark:bg-[var(--background-component-module-dark)] py-6 px-4 rounded-2xl flex flex-col gap-6 ">

        <div className="w-full   flex-col justify-start items-start inline-flex">

          <div className="w-full h-7 justify-between items-center inline-flex">

            <span className="w-full text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-[18px] font-semibold">
              {t("Vank.Share.Sidebar.ReceiveModal.title")}
            </span>

            <div
              className="w-7 h-7 p-0 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#818282] rounded-[500px] justify-center items-center gap-2.5 flex hover:bg-[var(--content-main-black-light)] dark:hover:bg-[#FFFFFF] transition-all duration-500 cursor-pointer"
              onMouseEnter={() => handleMouseHoverClose(true)}
              onMouseLeave={() => handleMouseHoverClose(false)}
              onClick={() => {
                setIsOpenReload(false);
                handleMouseHoverClose(false);
              }}
            >
              <IconClose
                className="w-full h-full transition-all duration-500"
                fill={
                  theme !== "dark" && isHovered
                    ? "#FFFFFF"
                    : theme === "dark" && isHovered
                      ? "#2D2E2F"
                      : "#C0C0C1"
                }
              />
            </div>

          </div>


        </div>

        <div className="flex flex-col gap-4">

          {/* Cripto */}
          <div
            className=" border border-[#EAEAEA] dark:border-[var(--strokes-pills-default-light)] 2xl:w-full xl:w-full lg:w-[162px] tablet:w-[162px] mobile:w-full  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[var(--background-hover-dark)] text-[var(--content-inner-inner-black-light)] dark:text-[var(--content-inner-inner-white-dark)] rounded-2xl flex items-center justify-between gap-8  cursor-pointer"
            onMouseEnter={() => handleMouseHoverCrypto(true)}
            onMouseLeave={() => handleMouseHoverCrypto(false)}
            onClick={() => handleNavigate("/receive/crypto")}
          >
            <div className="flex gap-2 items-center">

              <span>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2388_63043)">
                    <rect width="44" height="44" rx="22" fill="#FFD595" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1643 18.0278C19.8894 18.0278 18.0453 19.8622 18.0453 22.125C18.0453 24.3878 19.8894 26.2222 22.1643 26.2222C23.8703 26.2222 25.3377 25.1903 25.963 23.7132L29 24.9852C27.8784 27.6348 25.2423 29.5 22.1643 29.5C18.0695 29.5 14.75 26.1981 14.75 22.125C14.75 18.0519 18.0695 14.75 22.1643 14.75C25.2423 14.75 27.8784 16.6152 29 19.2648L25.963 20.5368C25.3377 19.0597 23.8703 18.0278 22.1643 18.0278Z" fill="#2D2E2F" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 12.25V17H19.5V12.25H21.5ZM21.5 27.5V31.75H19.5V27.5H21.5Z" fill="#2D2E2F" />
                    <path d="M24.5 12.25V17H22.5V12.25H24.5Z" fill="#2D2E2F" />
                    <path d="M24.5 27.5V31.75H22.5V27.5H24.5Z" fill="#2D2E2F" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2388_63043">
                      <rect width="44" height="44" rx="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </span>

              <div className=" text-start">
                <p className="text-base font-semibold">
                  {t("Vank.Share.Sidebar.ReceiveModal.Actions.Crypto.title")}
                </p>

                <p className="text-sm text-[#818282] dark:text-[var(--content-main-gray-dark)] w-full">
                  {t("Vank.Share.Sidebar.ReceiveModal.Actions.Crypto.text")}
                </p>
              </div>

            </div>

            <div>
              <ArrowHide className="-rotate-90 w-full h-full " />
            </div>

          </div>

          {/* dinero */}
          <div
            className=" border border-[#EAEAEA] dark:border-[var(--strokes-pills-default-light)] 2xl:w-full xl:w-full lg:w-[162px] tablet:w-[162px] mobile:w-full  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[var(--background-hover-dark)] text-[var(--content-inner-inner-black-light)] dark:text-[var(--content-inner-inner-white-dark)]  rounded-2xl flex items-center justify-between gap-3  cursor-pointer"
            onMouseEnter={() => handleMouseHoverFiat(true)}
            onMouseLeave={() => handleMouseHoverFiat(false)}
            onClick={() => handleNavigate("/receive/fiat")}
          >

            <div className="flex gap-2">

              <span>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2388_63054)">
                    <rect width="44" height="44" rx="22" fill="#FFF133" />
                    <g filter="url(#filter0_d_2388_63054)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8855 18.9479C16.8855 16.2173 19.4024 14.3809 22.0034 14.3809C23.8751 14.3809 25.6108 15.2878 26.5103 16.7746L27.2498 17.9969L24.8052 19.4758L24.0657 18.2535C23.7418 17.7181 22.9926 17.238 22.0034 17.238C20.5295 17.238 19.7427 18.2113 19.7427 18.9479C19.7427 19.2691 19.8534 19.5111 20.2368 19.7954C20.6863 20.1286 21.4016 20.4232 22.4016 20.7115C23.466 21.0184 24.5961 21.4259 25.4761 22.0796C26.422 22.7823 27.1236 23.8113 27.1236 25.2226C27.1236 27.9538 24.6061 29.7897 22.0034 29.7897C20.1325 29.7897 18.4 28.8816 17.5012 27.396L16.7617 26.1737L19.2063 24.6948L19.9458 25.9171C20.2704 26.4536 21.018 26.9325 22.0034 26.9325C23.4803 26.9325 24.2665 25.9588 24.2665 25.2226C24.2665 24.9003 24.1553 24.6576 23.7724 24.3732C23.3237 24.0399 22.6091 23.7449 21.61 23.4568C20.5463 23.1501 19.4158 22.7434 18.5353 22.0906C17.5888 21.389 16.8855 20.3604 16.8855 18.9479Z" fill="#2D2E2F" />
                      <path d="M20.8093 12.7142H22.7141V15.5714H20.8093V12.7142Z" fill="#2D2E2F" />
                      <path d="M20.8093 28.4285H22.7141V31.2857H20.8093V28.4285Z" fill="#2D2E2F" />
                    </g>
                  </g>
                  <defs>
                    <filter id="filter0_d_2388_63054" x="8" y="10" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2388_63054" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2388_63054" result="shape" />
                    </filter>
                    <clipPath id="clip0_2388_63054">
                      <rect width="44" height="44" rx="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </span>

              <div className=" text-start">
                <p className="text-base font-semibold">
                {t("Vank.Share.Sidebar.ReceiveModal.Actions.Fiat.title")}
                </p>

                <p className="text-sm text-[#818282] dark:text-[var(--content-main-gray-dark)]">
                {t("Vank.Share.Sidebar.ReceiveModal.Actions.Fiat.text")}
                </p>
              </div>

            </div>

            <div>
              <ArrowHide className="-rotate-90 w-full h-full " />
            </div>

          </div>

        </div>



      </div>
    </CustomModal>
  );
};

export default ReceiveModal;