import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTime } from "../RootVank";
import ServiceSidebar from "@/apps/Shared/layout/Sildebar/ServiceSidebar";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";

const ModalInactAndExpi = ({
  type,
  isClose,
  setIsOpenExpiry,
}: {
  type: string;
  isClose: any;
  setIsOpenExpiry: any;
}) => {
  const [t] = useTranslation("global");

  const [isLoading, setIsLoading] = useState(false);
  const serviceSidebar = new ServiceSidebar();
  // Inicializa el estado, poniendo true si "Inactive" no existe en localStorage
  const [isInactive, setIsInactive] = useState(false);
  const [count, setCount] = useState(0);

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const handleClear = async () => {
    await StorageService.delete("token");
    await StorageService.delete("verify");
    localStorage.removeItem("ip");
    localStorage.removeItem("ipTimestamp");
    localStorage.removeItem("id");
  };

  useEffect(() => {
    if (type && isClose) {
      // Mostramos la animación de carga después de 5 segundos
      setTimeout(() => {
        setIsLoading(true);
        setTimeout(() => {
          serviceSidebar
            .LogOutUser()
            .then(async () => {
              handleClear()
              await StorageService.set("inactivity", true);
              setIsOpenExpiry(false)
              window.location.href = "/";
            })
            .catch((error) => {
              console.error("Error during logout:", error);
              localStorage.setItem("counter", "1");
            })
            .finally(async () => {
              setIsLoading(false); // Desactiva el estado de carga, tanto si la operación es exitosa como si falla
            });
        }, 1000);
      }, 1000);
      localStorage.setItem("counter", "1");
    }
  }, [type, isClose]);

  useEffect(() => {
    const handleIsInact = async () => {
      const isInact = await StorageService.get("inactivity"); // Llama al método de forma síncrona si es posible
      setIsInactive(isInact);
    };

    const handleBeforeUnload = async () => {
      // Obtener el valor actual del contador desde localStorage y convertirlo a número
      const savedCount = parseInt(localStorage.getItem("counter") || "1", 10);
      // Alternar entre 0 y 1 dependiendo del valor guardado
      const newCount = savedCount === 1 ? 0 : 1;
      setCount(newCount);

      // Guardar el nuevo valor en localStorage
      localStorage.setItem("counter", newCount.toString());
      if (newCount === 1) {
        await StorageService.set("inactivity", false);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    handleIsInact();
  }, []);

  return (
    <motion.div
    onClick={(e) => e.stopPropagation()}
    className="relative w-full max-w-[420px] h-auto min-h-[270px] bg-[--color-lightest-white]  dark:bg-[#1A1A1B] cursor-default flex flex-col rounded-[24px] overflow-hidden"
    initial={{
      scale: 0,
    }}
    animate={{
      scale: 1,
      transition: {
        duration: 0.3,
      },
    }}
    exit={{
      scale: 0,
    }}
  >
    <div className="w-full h-[35%] pt-4 flex justify-center items-center ">
      <div className="p-5 w-20 h-20 bg-red-200 rounded-full">
        <svg
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ff3838"
          stroke="#ff3838"
          stroke-width="0.00048000000000000007"
          className="h-full w-full"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <title>exclamation-point</title>{" "}
            <g id="Layer_2" data-name="Layer 2">
              {" "}
              <g id="invisible_box" data-name="invisible box">
                {" "}
                <rect width="48" height="48" fill="none"></rect>{" "}
              </g>{" "}
              <g id="icons_Q2" data-name="icons Q2">
                {" "}
                <g>
                  {" "}
                  <circle cx="24" cy="40" r="3"></circle>{" "}
                  <path d="M23.8,33h.4a2.2,2.2,0,0,0,2.1-2L28,7.3a4,4,0,1,0-8,0L21.7,31A2.2,2.2,0,0,0,23.8,33Z"></path>{" "}
                </g>{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
    </div>
    <div className="w-full h-[65%] px-5 py-4 flex flex-col items-center gap-6">
      <div className="flex flex-col justify-center items-center gap-3 ">
        <h2 className="text-center text-3xl font-bold mb-3 dark:text-[#FFFFFF]">
            {t("Share.Expire.Title")}
        </h2>
        <p className="text-center text-xl leading-6 font-medium dark:text-[#FFFFFF]">
        {isInactive
          ? t("Share.Expire.message")
          : t("Share.Expire.messageTwo")}
        </p>
      </div>
      <button
        className={`w-full h-[46px] px-12 py-[5px] bg-[--color-dark-yellow] hover:bg-[--color-darkest-grey] dark:hover:bg-[#FFFFFF] dark:hover:text-[#2D2E2F] text-[#303030] hover:text-[--color-dark-yellow] disabled:opacity-75 disabled:cursor-default rounded-[500px] justify-center items-center gap-2.5 inline-flex cursor-pointer transition-all duration-300
          ${!isInactive ? "hidden" : ""}
          `}
        onClick={async () => {
          setIsOpenExpiry(false);

          await StorageService.set("inactivity", false);
        }}
      >
        {t("Share.Expire.Accept")}
      </button>
    </div>
      {isLoading && (
        <div className="absolute inset-0 flex flex-col items-center justify-center gap-3 bg-white dark:bg-[#1A1A1B]/95 z-50">
          <svg
            className="loader"
            viewBox="0 0 384 384"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="active"
              pathLength="360"
              fill="transparent"
              stroke-width="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
            <circle
              className="track"
              pathLength="360"
              fill="transparent"
              stroke-width="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
          </svg>
          <p className="text-base text-[var(--content-main-black-light)] dark:text-white">
            Cerrando sesión...
          </p>
        </div>
      )}
  </motion.div>
  );
};

export default ModalInactAndExpi;
