import React from "react";

const VisibleOff = ({ className }: { className?: string }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.2464 17.8294C14.3124 17.8954 14.2856 18.0109 14.1962 18.0398C8.90248 19.7352 3.17423 17.0065 1.41286 11.8922C1.36336 11.7478 1.36336 11.5869 1.41286 11.4419C1.92367 9.95893 2.78098 8.65956 3.87961 7.61043C3.92154 7.57056 3.98823 7.57193 4.0288 7.61249L6.98642 10.5701C7.00704 10.5907 7.01461 10.6196 7.00773 10.6478C6.24048 13.635 9.03448 16.4194 12.01 15.6624C12.0437 15.6535 12.0753 15.6597 12.1 15.6844C12.3462 15.9299 13.781 17.364 14.2464 17.8294Z"
        fill="#A2A2A2"
      />
      <path
        d="M16.9675 16.6621C18.6217 15.4988 19.9107 13.8578 20.5879 11.8901C20.6374 11.7458 20.6374 11.5876 20.5879 11.4433C19.2184 7.46469 15.3657 4.79169 11 4.79169C9.29574 4.79169 7.67186 5.20213 6.24186 5.93638L2.54861 2.24313C2.2798 1.97432 1.8453 1.97432 1.57649 2.24313C1.30767 2.51194 1.30767 2.94644 1.57649 3.21525L19.4515 21.0903C19.7203 21.3591 20.1548 21.3591 20.4236 21.0903C20.6924 20.8214 20.6924 20.3869 20.4236 20.1181L16.9675 16.6621ZM11 7.54169C13.275 7.54169 15.125 9.39175 15.125 11.6667C15.125 12.5563 14.839 13.3786 14.3585 14.053L8.61374 8.30825C9.28817 7.82769 10.1104 7.54169 11 7.54169Z"
        fill="#A2A2A2"
      />
    </svg>
  );
};

export default VisibleOff;
