import { useTheme } from "@/Context/UseContext/ThemeContext";

export const ArrowHide = ({
  className,
  classNamePath,
  stroke,
  onClick,
}: {
  className?: any;
  classNamePath?: any;
  stroke?: string;
  onClick?: () => void;
}) => {

    const { theme, toggleDarkMode } = useTheme(); // Usa el contexto


  return (
    <svg
    width="14"
    height="8"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    // className=" hover:scale-110 bg-red-600"
    onClick={onClick}
    >
      <path
        d="M1 1L7 8L13 1"
        className={`${classNamePath} stroke-[#818282]`}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
