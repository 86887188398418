import {atom}  from 'recoil'

export const postDataDownloadPdf=atom({
    key:'atomDataPdf',
    default:{
        CODERECEIPT:"",
        GENERATEDBY:"",
        URL:"",
        TYPESEND:""
    }
})