import React from 'react'
import './CustomLoading.css'

const CustomLoading = () => {
    return (
        <div className="h-6  flex items-center">

            <div className="loade">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>


        </div>


    )
}

export default CustomLoading