import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { formatBalance } from "../../../Home/Home";
import { SelectTransaction } from "../state";
import { counterSelector, ProfileUser } from "../../../Home/state";
import { CustomCircle } from "@/apps/Shared/CustomCircle/CustomCircle";
import {
  IconArrowLeft,
  IconClose,
  IconNoMovement,
  IconVankPayTransactions,
} from "@/assets/IconV2/IconsSvg";

// import { serviceOrchestrator } from "@/apps/services/ServicesOrchestrator";
import { ServiceOrchestrator } from "../../../Transactions/VankPay/service/ServicesOrchestrator";
import { ServicesHome } from "../../../Home/Services/ServicesHome";
import { formtDate } from "../../../utils/Utilfunctions";
import ModalApprovedShipping from "../../../Transactions/Fiat/components/ModalApprovedShipping/ModalApprovedShipping";
import ModalTableMovementsMobil from "../modals/ModalTableMovementsMobil";
import Convert from "../manners/Convert/Convert";
import ModalInfoTransaction from "../../ModalInfoTransaction/ModalInfoTransaction";
import CustomDataLoading from "@/apps/Shared/CustomDataLoading/CustomDataLoading";
import { AnimatePresence, motion } from "framer-motion";

// Función para capitalizar nombres
const capitalizeName = (name) => {
  if (!name) return name; // Si es un correo, lo devolvemos tal cual
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const Income = ({
  item,
  handleSelect,
}: {
  item?: any;
  handleSelect?: any;
}) => {
  const [t] = useTranslation("global");
  const [isAction, setIsAction] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [visibleModalInfo, setVisibleModalInfo] = useState<boolean>(false);

  return (
    <div
      onClick={handleSelect}
      className="group cursor-pointer border-t-[1px] dark:border-t-[var(--strokes-separator-disabled-dark)] relative w-full min-h-[56px] mobile-360:px-3 mobile:px-1 flex justify-between items-center gap-1  overflow-hidden"
    >
      <div className="w-full min-[1440px]:w-[70%] text-sm h-[36px] items-center gap-4 mobile:gap-2 inline-flex ">
        <span>
          <CustomCircle
            moreStyle={`${
              item.ACTION == "OUTPUT"
                ? "bg-[var(--error-bg-light)] "
                : "bg-[var(--success-backgound-light)]"
            } w-[36px] h-[36px] rotate-180`}
            icon={
              <IconArrowLeft
                className={` ${
                  item.ACTION == "OUTPUT"
                    ? "rotate-[136deg]  "
                    : "-rotate-[50deg]  "
                } w-[15px] h-[15px] `}
                stroke="black"
              />
              // <IconVankPayTransactions
              //   className={` ${item.ACTION == "OUTPUT"
              //     ? "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] "
              //     : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              //     } w-[15px] h-[15px]`}

              // />
            }
          />
        </span>

        <div className="w-full  max-h-[30px] flex-col justify-center gap-1 items-start inline-flex">
          <div className="h-3 boder justify-start items-center inline-flex">
            <div
              className={`mobile:flex mobile:flex-shrink-0 text-[#2D2E2F]  mobile:text-[14px] whitespace-nowrap ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "line-through text-[--content-disabled-input]"
                  : "dark:text-[#FFFFFF]"
              }`}
            >
              + {formatBalance(item.AMOUNT)} {item.ASSET}
            </div>
          </div>
          <div className="justify-start items-start inline-flex ">
            {/* <div className=" text-[--color-darkest-grey] dark:text-[#FFFFFF] mobile-375:text-[12px] mobile:text-[12px] font-normal w-full ">
                {item?.USUARIOVANK === "INTERNO"
                  ? `${capitalizeName(item.NAMEREMISOR?.split(" ")[0])} 
                  ${capitalizeName(item.SURNAMEEMISOR?.split(" ")[0])}`
                  : item?.USUARIOVANK === "EXTERNO" &&
                    t(
                      "Home.LatestTransactions.LatestTransactions.ExternalUser"
                    )}
              </div> */}
            <span
              className={`text-[#2D2E2F]  mobile:text-[12px] ${
                item?.STATUSORDER === 3 || item?.STATUSORDER === 4
                  ? "text-[--content-disabled-input]"
                  : "dark:text-[#FFFFFF]"
              }`}
            >
              {item?.USUARIOVANK === "INTERNO"
                ? t("Vank.Share.LatestTransactions.VankpayIncomeComplete")
                : item?.USUARIOVANK === "EXTERNO" &&
                  t("Home.LatestTransactions.LatestTransactions.ExternalUser")}
            </span>
          </div>
        </div>
      </div>

      <div className=" w-[15rem] text-end ">
        <div
          className={`h-9 justify-end items-center gap-4 inline-flex ${
            item?.STATUSORDER === 2 ? "" : "hidden"
          } ${
            !isAction ? "translate-x-0" : "translate-x-full"
          } transition-all duration-300`}
        >
          {/* <span className="text-[#818282] text-[12px] dark:text-[#C0C0C1] mobile:-mr-2 mobile-360:-mr-1 text-end mobile:text-[10px] w-[5rem]  ">
              {formtDate(item?.GENERATEDDATE)}
            </span> */}
          <div className="w-[54px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
            <div className="text-center text-[var(--content-main-white-ligth)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("")}
            </div>
          </div>
        </div>

        <div
          className={` h-[36px] flex justify-end items-center mobile:gap-2   ${
            item?.STATUSORDER === 1 ? "" : "hidden"
          } `}
        >
          {/* <span className="text-[#818282] dark:text-[#C0C0C1] w-[5rem] mobile:text-[10px]  ">
              {formtDate(item?.GENERATEDDATE)}
            </span> */}
          <div className="w-[64px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.Made")}
            </div>
          </div>
        </div>

        <div
          className={`h-[36px] flex justify-end items-center gap-1 mobile:gap-4 ${
            item?.STATUSORDER === 3
              ? "text-[--content-disabled-input]"
              : "hidden"
          }`}
        >
          {/* <span className="text-[--color-darkest-grey] w-full">
              <div
                className={`text-[#818282] dark:text-[#C0C0C1] w-[5rem] mobile:-mr-2 text-end mobile:text-[10px] ${item?.STATUSORDER === 3 ? "text-[--content-disabled-input]" : ""
                  }`}
              >
                {formtDate(item.GENERATEDDATE)}
              </div>
            </span> */}
          <div className="w-[64px] h-6 p-2 bg-[var(--strokes-separator-disabled-light)] dark:bg-[var(--strokes-separator-disabled-dark)] rounded-[24px] justify-center items-center inline-flex">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.Cancel")}
            </div>
          </div>
        </div>

        <div
          className={` h-[36px] flex justify-end items-center mobile:gap-2   ${
            item?.STATUSORDER === 4
              ? "text-[--content-disabled-input]"
              : "hidden"
          } `}
        >
          {/* <span className="text-[#818282] dark:text-[#C0C0C1] w-[5rem] mobile:text-[10px]  ">
              {formtDate(item?.GENERATEDDATE)}
            </span> */}
          <div className="w-[64px] h-6 p-2 bg-[var(--error-chip-light)] rounded-[24px] justify-center items-center inline-flex">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.Failed")}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="2xl:hidden 2xl:group-hover:hidden ml-10  h-full justify-center group-hover:flex group-hover:opacity-100 flex-col bg-[#FFf] opacity-0    absolute text-sm ">
                    <span>
                        {item?.USUARIOVANK === "INTERNO"
                            ? `${item.NAMERECEPTOR?.split(" ")[0]} ${item.NAMERECEPTOR?.split(" ").slice(-1)[0]}`
                            : item?.USUARIOVANK === "EXTERNO" && "Usuario Externo"}
                    </span>
                    <span>{formtDate(item?.GENERATEDDATE)}</span>
                </div> */}
    </div>
  );
};

const Expense = ({ item, handleSelect }) => {
  const servicesHome = new ServicesHome();
  const [isAction, setIsAction] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [t] = useTranslation("global");
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  return (
    <div
      onClick={handleSelect}
      className="group cursor-pointer border-t-[1px] dark:border-t-[var(--strokes-separator-disabled-dark)] relative w-full min-h-[56px] mobile:px-1 mobile-360:px-3 flex justify-between items-center gap-1 overflow-hidden"
    >
      <div className="w-full min-[1440px]:w-[70%] text-sm h-[36px] items-center gap-4  mobile:gap-2 inline-flex ">
        <span>
          <CustomCircle
            moreStyle={`${
              item.ACTION == "OUTPUT"
                ? "bg-[var(--error-bg-light)]"
                : " bg-[var(--success-backgound-light)]"
            } w-[36px] h-[36px] rotate-180`}
            icon={
              <IconArrowLeft
                className={` ${
                  item.ACTION == "OUTPUT"
                    ? "rotate-[136deg]"
                    : "-rotate-[50deg]"
                } w-[15px] h-[15px]`}
                stroke="black"
              />
              // icon={
              //   <IconVankPayTransactions
              //   className={` ${item.ACTION == "OUTPUT"
              //     ? "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              //     : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              //     } w-[15px] h-[15px]`}

              //   />
            }
          />
        </span>

        <div className="w-full flex-col justify-center items-start inline-flex">
          <div className=" justify-start items-center inline-flex">
            <div className="flex flex-col w-full gap-1 ">
              <div
                className={`mobile:flex mobile:flex-shrink-0 mobile:text-[14px] whitespace-nowrap ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4 || item.STATUSORDER === 5
                    ? "line-through text-[--content-disabled-input]"
                    : "text-[#2D2E2F] dark:text-[#FFFFFF] "
                }`}
              >
                - {formatBalance(item.AMOUNT)} {item.ASSET}
              </div>

              {/* <div className="text-[#2D2E2F] dark:text-[#FFFFFF] mobile-375:text-[12px] mobile:text-[12px] -mt-1">
                  {item?.USUARIOVANK === "INTERNO"
                    ? `${capitalizeName(item?.COMPLETENAMERECEPTOR?.split(" ")[0])} 
                     ${capitalizeName(item?.SURNAMERECEPTOR?.split(" ")[0])}`
                    : item?.USUARIOVANK === "EXTERNO" && t("Home.LatestTransactions.LatestTransactions.ExternalUser")}
                </div> */}
              <span
                className={` mobile:text-[12px] -mt-1 ${
                  item?.STATUSORDER === 3 || item?.STATUSORDER === 4 || item.STATUSORDER === 5
                    ? "text-[--content-disabled-input]"
                    : "text-[#2D2E2F] dark:text-[#FFFFFF]"
                }`}
              >
                {item?.USUARIOVANK === "INTERNO"
                  ? t("Vank.Share.LatestTransactions.SendVankpayComplete")
                  : item?.USUARIOVANK === "EXTERNO" &&
                    t(
                      "Home.LatestTransactions.LatestTransactions.ExternalUser"
                    )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="text-end ">
        <div
          className={`h-9 flex justify-end items-center ${
            item?.STATUSORDER === 2 ? "" : "hidden"
          } ${
            !isAction ? "translate-x-0" : "translate-x-full"
          } transition-all duration-300`}
        >
          {/* <span className="text-[--color-darkest-grey] w-full  ">
              <div className="text-[#818282] dark:text-[#C0C0C1] mobile:-mr-2 mobile-360:-mr-1   text-end mobile:text-[10px] w-[5rem]">
                {formtDate(item.GENERATEDDATE)}
              </div>
            </span> */}
          <div className="w-[64px] h-[24px]  p-2 bg-[#FFB94F] rounded-3xl justify-center items-center inline-flex ">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.earring")}
            </div>
          </div>
        </div>

        <div
          className={` h-[36px] flex justify-end items-center mobile:gap-2 mobile-360:gap-2  ${
            item?.STATUSORDER === 1 ? "" : "hidden"
          } `}
        >
          {/* <div className="text-[#818282] dark:text-[#C0C0C1] w-[5rem]  text-end mobile:text-[10px] ">
              {formtDate(item.GENERATEDDATE)}
            </div> */}

          <div className="w-[64px] h-6 p-2 bg-[#6AD67B] rounded-[24px]  justify-center items-center inline-flex ">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.Made")}
            </div>
          </div>
        </div>

        <div
          className={`h-[36px] flex justify-end items-center gap-1 mobile:gap-4 ${
            item?.STATUSORDER === 3 
              ? "text-[--content-disabled-input]"
              : "hidden"
          }`}
        >
          {/* <span className="text-[--color-darkest-grey] w-full">
              <div
                className={`text-[#818282] dark:text-[#C0C0C1] w-[5rem] mobile:-mr-2 text-end mobile:text-[10px] ${item?.STATUSORDER === 3 ? "text-[--content-disabled-input]" : ""
                  }`}
              >
                {formtDate(item.GENERATEDDATE)}
              </div>
            </span> */}
          <div className="w-[64px] h-6 p-2 bg-[var(--strokes-separator-disabled-light)] dark:bg-[var(--strokes-separator-disabled-dark)] rounded-[24px] justify-center items-center inline-flex">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[var(--content-main-white-dark)] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.Cancel")}
            </div>
          </div>
        </div>

        <div
          className={`h-[36px] flex justify-end items-center mobile:gap-4  ${
            item?.STATUSORDER === 4 || item.STATUSORDER === 5 ? "" : "hidden"
          }`}
        >
          <span className="text-[--color-darkest-grey]  mobile:-ml-0 ">
            {/* <div
                className={`text-[#818282] dark:text-[#C0C0C1] w-[5rem] mobile:-mr-2 text-end mobile:text-[10px] ${item?.STATUSORDER === 4 ? "text-[--content-disabled-input]" : ""
                  }`}
              >
                {formtDate(item.GENERATEDDATE)}
              </div> */}
          </span>
          <div className="w-[64px] h-6 p-2 bg-[var(--error-chip-light)] rounded-[24px] justify-center items-center inline-flex">
            <div className="text-center text-[var(--content-main-white-light)] dark:text-[#FFF] text-[10px] font-bold">
              {t("Home.LatestTransactions.LatestTransactions.Failed")}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="2xl:hidden 2xl:group-hover:hidden ml-10  h-full justify-center group-hover:flex group-hover:opacity-100 flex-col bg-[#FFf] opacity-0    absolute text-sm ">
                    <span>
                        {item?.USUARIOVANK === "INTERNO"
                            ? `${item.COMPLETENAME?.split(" ")[0]} ${item.COMPLETENAME?.split(" ").slice(-1)[0]}`
                            : item?.USUARIOVANK === "EXTERNO" && "Usuario Externo"}
                    </span>
                    <span>{formtDate(item?.GENERATEDDATE)}</span>
                </div> */}
    </div>
  );
};

const TransactionsFiat = ({
  item,
  key,
  handleSelect,
}: {
  item?: any;
  key: number;
  handleSelect: any;
}) => {
  const [t, i18n] = useTranslation("global");
  const [modalInfo, setModalInfo] = useState(false);
  const [infoItem, setInfoItem] = useState<null>(null);

  const handleItemClick = (item) => {
    setInfoItem(item);
    setModalInfo(true);
  };

  

  return (
    <div
      className="flex justify-between mobile-360:px-3 mobile:px-1 border-t-[1px] py-1 border-[#F1F1F1] dark:border-t-[var(--strokes-separator-disabled-dark)]  gap-4 mobile:gap-2 group relative w-full "
      onClick={handleSelect}
    >
      <span>
        <CustomCircle
          moreStyle={`${
            item.NAME?.split(" ")[0] == "Fee" ? "bg-blue-200" : "bg-[#FDE1E1]"
          }  w-9 h-9 rotate-180`}
          icon={
            item.NAME?.split(" ")[0] == "Fee" ? (
              "%"
            ) : (
              <IconArrowLeft
                className={` ${
                  item.ACTION == "OUTPUT"
                    ? "-rotate-[50deg]"
                    : "rotate-[136deg]"
                } w-[15px] h-[15px]`}
                stroke="black"
              />
            )
          }
        />
      </span>

      <div className="w-full cursor-pointer  ">
        <div className="flex  items-center justify-between mobile:gap- ">
          <div className="flex flex-col mobile:w- ">
            <div
              className={`mobile:flex mobile:flex-shrink-0   whitespace-nowrap ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "line-through text-[--content-disabled-input]"
                  : "text-[#2D2E2F] dark:text-[#FFFFFF]"
              } ${
                item.AMOUNT >= 10000000
                  ? "mobile:text-[12px] mobile-375:text-[14px] "
                  : "mobile:text-[14px]  "
              }`}
            >
              - {formatBalance(item.AMOUNT)} {item.ASSETNAME}
            </div>

            {/* <div className="text-[#2D2E2F] dark:text-[#FFFFFF]  mobile-375:text-[12px] mobile:text-[12px] ">
                {capitalizeName(`${item?.NAME?.split(' ')[0]} ${item?.SURNAME?.split(' ')[0]}`)}
              </div> */}
            <span
              className={`mobile-375:w-[10rem] min-[360px]:w-[9rem] mobile:w-[8rem]  mobile:text-[12px] overflow-hidden break-words  ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? " text-[--content-disabled-input]"
                  : "text-[#2D2E2F] dark:text-[#FFFFFF]"
              }`}
            >
              {t(
                "Vank.Transaction.Money.Tables.TableTransactionFiat.SendFiatComplete"
              )}
            </span>
          </div>

          {/* <div className="flex items-center mobile:w-full mobile-360:-ml-8  mobile:-ml-2 ">
              <div
                className={`text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-end mobile:text-[10px] min-[360px]:w-[5rem]
                  ${item.STATUSORDER == 1  ? " mobile:ml-2 mobile-375:ml-6  " : ""} 
                   ${item.STATUSORDER == 3 ? " mobile:ml-2  mobile-375:ml-6 min-[360px]:-mr-6 text-[#c0c0c1]  " : ""}
                   ${item.STATUSORDER == 4 ? " mobile:ml-2  mobile-375:ml-6 text-[#c0c0c1]" : ""}
                  ${item.STATUSORDER == 2 ? " mobile:-ml-5 mobile-375:ml-0 min-[360px]:-mr-4  " : ""}`}
              >
                {formtDate(item.GENERATEDDATE)}
              </div>

              <div className="mobile:w-full  flex justify-end mobile:-ml-6 mobile-360:-ml-14">
                <span
                  className={`text-[var(--content-main-white-light)] dark:text-[#FFFFFF] rounded-3xl px-2 py-1 h-6 font-bold mobile:text-[10px] ${item.STATUSORDER == 1
                    ? "bg-[var(--success-chip-light)]"
                    : item.STATUSORDER == 2
                      ? `bg-[var(--pending-pending-chips-light)] `
                      : item.STATUSORDER == 3
                        ? "bg-[var(--error-chip-light)] dark:bg-[var(--error-chip-light)] "
                        : "bg-[var(--error-chip-light)]  "
                    }`}
                >
                  {item.STATUSORDER == 1 &&
                    t("Vank.Transaction.Money.Tables.TableTransactionFiat.made")}
                  {item.STATUSORDER == 2 &&
                    t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.pending"
                    )}
                  {item.STATUSORDER == 3 &&
                    t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.Failed"
                    )}
                  {item.STATUSORDER == 4 &&
                    t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.Failed"
                    )}
                </span>
              </div>
            </div> */}

          {/* <div className="flex items-center mobile:w-full mobile-360:-ml-8  mobile:-ml-2 "> */}
          <div className="flex gap- items-center    justify-end gap-2 ">
            {/* <div
                // className={`text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-end mobile:text-[10px] min-[360px]:w-[5rem]
                className={`text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]  mobile:text-[10px] text-end  w-16  
                  ${item.STATUSORDER == 1 ? " mobile:ml-2 mobile-375:ml-6  " : ""} 
                   ${item.STATUSORDER == 3 ? " text-[#c0c0c1]  " : ""}
                   ${item.STATUSORDER == 4 ? "" : ""}
                  ${item.STATUSORDER == 2 ? "   " : ""}`}
              >
                {formtDate(item.GENERATEDDATE)}
              </div> */}

            {/* <div className="mobile:w-full  flex justify-end mobile:-ml-6 mobile-360:-ml-14"> */}

            <div
              className={`w-[64px] text-[var(--content-main-white-light)] font-semibold text-center rounded-3xl px-2 py-1 h-6 font-bold mobile:text-[10px]   ${
                item.STATUSORDER == 1
                  ? "bg-[var(--success-chip-light)] dark:text-[var(--content-main-white-dark)]"
                  : item.STATUSORDER == 2
                  ? `bg-[var(--pending-pending-chips-light)] dark:text-[var(--content-main-white-dark)] `
                  : item.STATUSORDER == 3
                  ? "bg-[var(--error-chip-light)] dark:text-[var(--content-main-white-dark)] "
                  : "bg-[var(--error-chip-light)] dark:text-[var(--content-main-white-dark)] "
              }`}
            >
              {item.STATUSORDER == 1 &&
                t("Vank.Transaction.Money.Tables.TableTransactionFiat.made")}
              {item.STATUSORDER == 2 &&
                t("Vank.Transaction.Money.Tables.TableTransactionFiat.pending")}
              {item.STATUSORDER == 3 &&
                t("Vank.Transaction.Money.Tables.TableTransactionFiat.Failed")}
              {item.STATUSORDER == 4 &&
                t("Vank.Transaction.Money.Tables.TableTransactionFiat.Failed")}
            </div>
          </div>
        </div>
      </div>

      {/* <>
          {indexItem == index && modalInfo &&


            <CustomModal
              isOpen={indexItem == index && modalInfo}
            >
              <ModalInfoTransaction onClick={() => setModalInfo(false)} data={infoItem} />

            </CustomModal>

          }

        </> */}

      {/* tarifa transaccional */}
      {/* </div>

            <div className='flex  px-4 border-t-[1px] py-2 border-[#F1F1F1] dark:border-[#2D2E2F] gap-2 group  relative  w-full ' onClick={() => handleItemClick(item)}>

                <span>
                    <CustomCircle moreStyle={'border-2 border-[#FDE1E1] bg-[#fff] text-[#000000] w-9 h-9'} icon={"%"} />
                </span>

                <div className='w-full cursor-pointer flex items-center' >
                    <div className='flex w-full items-center justify-between  '>

                        <div className='flex flex-col w-1/2'>
                            <div className="flex gap-2 items-center">
                                <div className=' text-[#2D2E2F] dark:text-[#FFFFFF] mobile-375:text-[14px] mobile:text-[12px]'>- {formatBalance(item.AMOUNT)} {item.ASSETNAME} {item.FEE * 10}%</div>
                            </div>
                        </div>

                    </div>

                </div> */}

      {/* <>
          {indexItem == index && modalInfo &&


            <CustomModal
              isOpen={indexItem == index && modalInfo}
            >
              <ModalInfoTransaction onClick={() => setModalInfo(false)} data={infoItem} />

            </CustomModal>

          }

        </> */}
    </div>
  );
};

const Conversion = ({
  item,
  handleSelect,
}: {
  item: any;
  handleSelect: any;
}) => {
  const [isAction, setIsAction] = useState(false);
  const [t] = useTranslation("global");
  // const [select, setSelect] = useRecoilState<any>(SelectTransaction)

  return (
    <div
      className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden"
      onClick={() => handleSelect(item)}
    >
      <div className="w-[256px] h-[36px] justify-center items-center gap-2  inline-flex">
        <div className="w-[36px] min-h-[36px] justify-center items-center flex bg-[#FFE6C0] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 14.9143L10 18V6"
              stroke="#2D2E2F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17 9.08571L14 6V18"
              stroke="#2D2E2F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
          <div className="h-3 justify-start items-center inline-flex">
            <div className="text-[--color-darkest-grey] text-base font-normal ">
              {t("Home.LatestTransactions.LatestTransactions.Conversion")}
            </div>
          </div>
          <div className="justify-start items-start gap-2 inline-flex">
            <div className="text-[--color-darkest-grey] text-xs font-normal ">
              {t("Home.LatestTransactions.LatestTransactions.Ordermade")}
            </div>
            {/* <div className="text-[#818282] text-xs font-normal ">
              {formtDate(item?.GENERATEDDATE)}
            </div> */}
          </div>
        </div>
      </div>

      <div
        className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
          item?.STATEPROCESSINVEST === 2 ? "" : "hidden"
        } ${
          !isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{item?.AMOUNT} {item?.ASSET}</span> */}
        <div className="w-[76px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
          <div className="text-center text-[--color-lightest-white] text-xs font-bold">
            Pen{t("Home.LatestTransactions.LatestTransactions.earring")}diente
          </div>
        </div>
        <button className="w-4 h-4" onClick={() => setIsAction(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 13L3 3M3 13L13 3"
              stroke="#2D2E2F"
              stroke-width="1.6"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
          item?.STATEPROCESSINVEST === 1 ? "" : "hidden"
        } `}
      >
        {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{formatBalance(1000)} {item?.ASSET}</span> */}
        <div className="w-[55px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Made")}
          </div>
        </div>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
          item?.STATEPROCESSINVEST === 3 ? "" : "hidden"
        } `}
      >
        <span className="text-[--color-darkest-grey] text-base font-semibold">
          {formatBalance(item?.AMOUNT)} {item?.ASSET}
        </span>
        <div className="w-[55px] h-6 p-2 bg-[#ffa4a4] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Cancel")}
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 w-[228px] h-[56px] py-4 bg-[#FFF] flex justify-center items-center rounded-l-[8px] shadow ${
          isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <div className="w-[163px] h-[24px] flex justify-center items-center gap-3">
          <span className="w-[87px] text-[12px] text-[--color-darkest-grey]">
            {t("Home.LatestTransactions.LatestTransactions.Areyousure")}
          </span>
          <div className="w-16 h-6 justify-center items-start gap-1 inline-flex">
            <div className="w-[27px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer">
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.yes")}
              </div>
            </div>
            <button
              className="w-[33px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer"
              onClick={() => setIsAction(false)}
            >
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.No")}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 -right-2 w-[514px] h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
        <div className="w-full h-px bg-gray-200" />
      </div>
    </div>
  );
};

const TransactionBatch = ({ item }) => {
  const [isAction, setIsAction] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [t] = useTranslation("global");

  return (
    <div className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden">
      <div className="w-[175px] h-[36px] justify-center items-center gap-2  inline-flex">
        <div className="w-full min-[1440px]:min-w-[36px] min-h-[36px] justify-center items-center flex bg-[#FDE1E1] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M13.0367 1.34631L3.05675 11.3463M13.0367 1.34631H9.05675M13.0367 1.34631V5.34631M13.0567 10.218L11.5589 8.71226M13.0567 10.218L12.0592 12.2115M13.0567 10.218C13.0567 10.218 10.5984 10.6021 9.05674 11.218C7.1909 11.9635 5.55696 13.6537 5.55696 13.6537M5.24619 1.55638L6.5632 3.22259M5.24619 1.55638L3.14821 2.30983M5.24619 1.55638C5.24619 1.55638 4.57262 3.95159 3.77771 5.40911C2.81567 7.17308 0.943207 8.59447 0.943207 8.59447"
              stroke="#2D2E2F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
          <div className="h-3 justify-start items-center inline-flex">
            <div className="text-[--color-darkest-grey] dark:text-[#FFFFFF] text-base font-normal ">
              {item?.name}
            </div>
          </div>
          <div className="justify-start items-start gap-2 inline-flex">
            <div className="text-[#818282] dark:text-[#C0C0C1] text-xs font-normal ">
              {item?.date}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
          item?.status !== 0 && "hidden"
        } ${
          !isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <span className="text-[--color-darkest-grey] text-base font-semibold">
          {item?.free} {item?.asset}
        </span>
        <div className="w-[76px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
          <div className="text-center text-[--color-lightest-white] text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.earring")}
          </div>
        </div>
        <button className="w-4 h-4" onClick={() => setIsAction(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 13L3 3M3 13L13 3"
              stroke="#2D2E2F"
              stroke-width="1.6"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-center items-center gap-4 ${
          item?.status === 0 && "hidden"
        } `}
      >
        <span className="text-[--color-darkest-grey] dark:text-[#C0C0C1] text-base font-semibold">
          {item?.free} {item?.asset}
        </span>
        <div className="w-[55px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Made")}
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 w-[228px] h-[56px] py-4 bg-[#FFF] flex justify-center items-center rounded-l-[8px] shadow ${
          isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <div className="w-[163px] h-[24px] flex justify-center items-center gap-3">
          <span className="w-[87px] text-[12px] text-[--color-darkest-grey]">
            {t("Home.LatestTransactions.LatestTransactions.Areyousure")}
          </span>
          <div className="w-16 h-6 justify-center items-start gap-1 inline-flex">
            <div className="w-[27px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer">
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.yes")}
              </div>
            </div>
            <button
              className="w-[33px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer"
              onClick={() => setIsAction(false)}
            >
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.No")}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 -right-2 w-[514px] h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
        <div className="w-full h-px bg-gray-200 dark:bg-[#2D2E2F]" />
      </div>
    </div>
  );
};

const LatestTransactions = ({
  listTransactions,
  isLoadingHistory,
  className,
  Onclose,
}: {
  listTransactions: any;
  isLoadingHistory?: any;
  className?: string;
  Onclose: any;
}) => {
  const orchestratorService=new ServiceOrchestrator()
  const [t, i18n] = useTranslation("global");
  const [isConversion, setIsConversion] = useState(false);
  const [isOpenModalMobile, setIsOpenModalMobile] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const [fill, setFill] = useState<string>("#818282");

  // const servicesOrchestractor = new ServiceOrchestrator();

  // summary modal states
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
  const [isMoneyModalOpen, setIsMoneyModalOpen] = useState(false);

  const [expenseInfo, setExpenseInfo] = useState(null); // Estado para información de gastos
  const [incomeInfo, setIncomeInfo] = useState(null); // Estado para información de gastos
  const [moneyInfo, setMoneyInfo] = useState(null); // Estado para información de dinero
  // ------------------

  const OnboardingData = [
    {
      id: 1,
      change: "+1.02%",
      rate: "$1.12",
      name: "1200 USDT ⭢ 1380 USDT",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 2,
      change: "-0.56%",
      rate: "$1.12",
      name: "Conversión",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 2,
    },
    {
      id: 3,
      change: "-0.56%",
      rate: "$1.12",
      name: "Conversión",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 1,
    },
    //  -------------
    {
      id: 4,
      change: "-0.56%",
      rate: "$1.12",
      name: "Katlin Rogers",
      price: "+ $1,420.72 USDT ",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 5,
      change: "-0.56%",
      rate: "$1.12",
      name: "Recepción Crypto",
      price: "- $1,420.72 USD",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 6,
      change: "-0.56%",
      rate: "$1.12",
      name: "Recepción Crypto",
      price: "- $234,000 COP",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
  ];

  // const _className = className ? className : 'relative w-full min-[1440px]:min-w-[530px] h-[398px] bg-white rounded-2xl flex-col justify-start items-start inline-flex'
  const _className = `${
    className ? className : ""
  } 2xl:relative   2xl:w-[580px] xl:2xl:w-[580px] dark:bg-[--background-component-module-dark] lg:2xl:w-[580px] md:2xl:w-[580px] mobile:w-full xl:relative lg:relative md:relative sm:absolute mobile:absolute  bottom-0 bg-white flex-col justify-start items-start inline-flex pb-1 rounded-t-[16px]  ${
    counter === 6 && "z-50"
  }`;

  const handleSelect = (item, type) => {
    // Eliminar valores anteriores
    setExpenseInfo(null);
    setMoneyInfo(null);

    // Mostrar la modal correspondiente según el tipo de transacción
    switch (type) {
      case "expense":
        setExpenseInfo(item); // Asigna el valor de item para "expense"
        setIsExpenseModalOpen(true);
        break;
      case "income":
        setIncomeInfo(item); // Asigna el valor de item para "expense"
        setIsIncomeModalOpen(true);
        break;
      case "money":
        setMoneyInfo(item); // Asigna el valor de item para "money"
        // setMoneyInfo({ ...item, STATUSORDER: 4 });
        setIsMoneyModalOpen(true);
        break;
      // Agrega más casos para otros tipos de transacciones si es necesario
      default:
        // console.log("Tipo de transacción no reconocido");
        break;
    }
  };

  useEffect(() => {
   
  }, [listTransactions?.lengt > 0]);

  // extra functions of each item
  const handItemTransExpense = async (ite: any) => {
    try {
      const data = await orchestratorService.updateOrderVankPayUsers(ite);
      let response = data;

      if (response.status == 200) {
      }
    } catch (error) {
      // console.log(`${error} error`);
    }
  };
  // ------------------

  const newF = () => {};

  return (
    <>
      {/* {!expenseInfo && ( */}
      <motion.div
        className={_className}
        style={{
          pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
        }}
        initial={
          !isExpenseModalOpen || !isIncomeModalOpen || !isMoneyModalOpen
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Solo en móvil comienza fuera de la pantalla
        animate={
          isExpenseModalOpen || isIncomeModalOpen || isMoneyModalOpen
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Siempre termina en su posición original
        exit={
          !isExpenseModalOpen || !isIncomeModalOpen || !isMoneyModalOpen
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Sale hacia abajo solo en móvil
        transition={{ duration: 0.5 }} // Duración y suavizado de la transición
      >
        <div className="w-full h-[60px] p-4  flex justify-between items-center gap-6 border-b border-[#F1F1F1] dark:border-[#2D2E2F]">
          <div className="flex flex-col items-start  w-full">
            <div className="flex justify-between px-[4px] w-full items-center">
              <p className="text-[#2D2E2F] dark:text-[#FFFFFF] text-sm font-semibold">
                {t("Mobile.TransactionHistory.transactions")}
              </p>
              <div
                onMouseEnter={() => setFill("white")}
                onMouseLeave={() => setFill("#818282")}
                onClick={Onclose}
                className={`h-8 w-8 cursor-pointer flex items-center p-0 border border-[#F1F1F1] rounded-full hover:bg-[--color-darkest-grey]`}
              >
                <IconClose fill={fill} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`w-full h-[90%] flex-col justify-start items-center ${
            users?.STATUSINCODE === 2 ? "overflow-hidden" : "overflow-y-auto"
          }`}
        >
          <div className="pl-1 w-full h-full">
            {users?.STATUSINCODE === 2 ? (
              OnboardingData.map((item, index) => (
                <div className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden">
                  <div className="w-[256px] h-[36px] justify-center items-center gap-2  inline-flex">
                    <div
                      className={`w-[36px] min-h-[36px] justify-center items-center flex ${
                        item.type === "convert"
                          ? "bg-[#FFE3B9]"
                          : "bg-[#E2F7D2]"
                      } rounded-[50px]`}
                    >
                      {item.type === "convert" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.40015 11.0982L7.56015 13.3199V4.67993M12.6001 6.90165L10.4401 4.67993V13.3199"
                            stroke="#2D2E2F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.94727 12.0527L12.0529 5.94714M5.94727 12.0527H9.61207M5.94727 12.0527V8.38794"
                            stroke="#2D2E2F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </div>

                    <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
                      <div className="h-3 justify-start items-center inline-flex">
                        <div className="text-[--color-darkest-grey] text-base font-normal ">
                          {item.name}
                        </div>
                      </div>
                      <div className="justify-start items-start gap-2 inline-flex">
                        {index === 1 && (
                          <span className="text-[--color-darkest-grey] text-xs font-normal ">
                            {t(
                              "Home.LatestTransactions.LatestTransactions.Ordermade"
                            )}
                            :
                          </span>
                        )}
                        <div className="text-[#818282] text-xs font-normal ">
                          {item.date}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
                      item?.STATEPROCESSINVEST === 2 ? "" : "hidden"
                    } translate-x-0 transition-all duration-300`}
                  >
                    {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{item?.AMOUNT} {item?.ASSET}</span> */}
                    <div className="w-[76px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
                      <div className="text-center text-[--color-lightest-white] text-xs font-bold">
                        {t(
                          "Home.LatestTransactions.LatestTransactions.earring"
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
                      item?.STATEPROCESSINVEST === 1 ? "" : "hidden"
                    } `}
                  >
                    <span className="text-[--color-darkest-grey] text-base font-semibold">
                      {item?.price}
                    </span>
                    <div className="w-[55px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
                      <div className="text-center text-white text-xs font-bold">
                        {t("Home.LatestTransactions.LatestTransactions.Made")}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
                      item?.STATEPROCESSINVEST === 3 ? "" : "hidden"
                    } `}
                  >
                    <span className="text-[--color-darkest-grey] text-base font-semibold">
                      + $1,420.72 USD
                    </span>
                    <div className="w-[55px] h-6 p-2 bg-[#ffa4a4] rounded-[24px] justify-center items-center inline-flex">
                      <div className="text-center text-white text-xs font-bold">
                        {t("Home.LatestTransactions.LatestTransactions.Cancel")}
                      </div>
                    </div>
                  </div>

                  {/* w-[514px] */}
                  <div className="absolute bottom-0 -right-2 w-full h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
                    <div className="w-full h-px bg-[#F1F1F1]" />
                  </div>
                </div>
              ))
            ) : !isLoadingHistory ? (
              <>
                {listTransactions?.length > 0 ? (
                  listTransactions.map((transaction, index) => {
                    // console.log(transaction, " hola evc");

                    switch (transaction?.ACTION) {
                      case "INPUT":
                        return (
                          <Income
                            key={index}
                            item={transaction}
                            handleSelect={() =>
                              handleSelect(transaction, "income")
                            }
                          />
                        );
                      case "OUTPUT":
                        return (
                          <Expense
                            key={index}
                            item={transaction}
                            handleSelect={() =>
                              handleSelect(transaction, "expense")
                            }
                          />
                        );
                      case "PAYMENTS":
                        return (
                          <TransactionsFiat
                            key={index}
                            item={transaction}
                            handleSelect={() =>
                              handleSelect(transaction, "money")
                            }
                          />
                        );
                      case "CONVERT":
                      // return <Conversion key={index} item={transaction} handleSelect={handleSelect} />;
                      case "batch":
                        return (
                          <TransactionBatch key={index} item={transaction} />
                        );
                      default:
                        return null;
                    }
                  })
                ) : (
                  <div className="w-full min-h-[224px] h-full flex flex-col justify-center items-center border-t-[1px] border-[var(--strokes-separator-separator-light)] dark:border-[#2D2E2F]">
                    <div className="w-[80%] h-full flex flex-col justify-center items-center rounded-2xl text-center text-[#818282] dark:text-[#C0C0C1] leading-none">
                      <IconNoMovement />
                      {t("Mobile.TransactionHistory.IconNoMovement")}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="w-full min-h-[224px] h-full flex justify-center items-center border-t-[1px] py-1 border-[var(--strokes-separator-separator-light)] dark:border-[#2D2E2F]">
                <CustomDataLoading />
              </div>
            )}
          </div>
        </div>

        {counter === 6 && (
          <div className="absolute min-w-[354px] min-h-[76px] rounded-[16px] top-0 right-[102%] flex justify-start items-start bg-white py-4 pl-4 pr-8 z-50">
            <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
              <span className="text-[14px] text-[#000] font-normal">
                {counter}
              </span>
            </div>
            <p className="text-[18px] text-left text-[#000] font-normal leading-[21.78px]">
              {t("Vank.Home.Onboarding.Six")}
            </p>
          </div>
        )}

        <CustomModal isOpen={isConversion}>
          <Convert setIsConversion={setIsConversion} />
        </CustomModal>
      </motion.div>

      <AnimatePresence>
        {isExpenseModalOpen && (
          <ModalInfoTransaction
            onclickCancel={() => handItemTransExpense(expenseInfo)}
            isExpenseModalOpen={isExpenseModalOpen}
            onClick={() => setIsExpenseModalOpen(false)}
            data={expenseInfo}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isIncomeModalOpen && (
          <ModalInfoTransaction
            isExpenseModalOpen={isIncomeModalOpen}
            onClick={() => setIsIncomeModalOpen(false)}
            data={incomeInfo}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isMoneyModalOpen && (
          <ModalApprovedShipping
            data={moneyInfo}
            isMoneyModalOpen={isMoneyModalOpen}
            onClose={() => setIsMoneyModalOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default LatestTransactions;
